/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";
$secondary-dark:  #212233;
$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";
@import 'bootstrap/dist/css/bootstrap.min.css';

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "ag-grid-community/dist/styles/ag-theme-material.css";



p-autoComplete > span {
  height: 38px !important;
  width: 100% !important;

  & > input {
    border-color: #b1b7c1 !important;
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  & > button {
    background: #1e1e1e !important;
    border: 1px solid #1e1e1e !important;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
}

p-calendar > span {
  height: 38px !important;
  width: 100% !important;

  & > input {
    border-color: #b1b7c1 !important;
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  & > button {
    background: #1e1e1e !important;
    border: 1px solid #1e1e1e !important;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
}

// start ad grid style
.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  --ag-internal-calculated-line-height: var(--ag-line-height, 25px);
}

.ag-theme-alpine-border{
  .ag-header-cell{
    margin-top: -1px;
  }
}
.ag-center-header .ag-header-cell-label {
  justify-content: center;
}

.ag-right-header .ag-header-cell-label {
  justify-content: right;
}

// ag header Group
.ag-right-header .ag-header-group-cell-label {
  justify-content: right;
}

.ag-center-header .ag-header-group-cell-label {
  justify-content: center;
  margin: 6px -18px;
}

.ag-group-header-border-left .ag-header-group-cell-label {
  border-left: 2px solid var(--ag-border-color, #babfc7);

}

.ag-group-header-border-right .ag-header-group-cell-label {
  border-right: 2px solid var(--ag-border-color, #babfc7);

}

.action-button{
  height: 30px;
  padding: 1px 15px;
}

.col-form-label-bold{
  font-weight: 500;
}

.grid-cell-centered {
  text-align: center;
}

.grid-cell-right {
  text-align: right;
}

.grid-cell-text-cut{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
   overflow: hidden;
}

.grid-cell-link {
  &:hover{
    cursor: pointer;
    color: blue;
    text-decoration-line: underline;
  }
}

.ag-theme-black {
  --ag-header-foreground-color: #FFFFFF;
  --ag-header-background-color: black;
}

.ag-theme-blue {
  --ag-header-foreground-color: #FFFFFF;
  --ag-header-background-color: #002e5f;
}
// end ad grid style

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #666666;
  border-color: #666666;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.075); //inset, 0 0 8px rgba(114, 96, 153, 0.6);
  outline: 0 none;
}

.form-label-end {
  text-align: start;

}

.form-label-center{
  text-align: start;
}

.w-btn {
  width: 100%;
  margin: 5px 0px;
}

@include media-breakpoint-up(xl) {
  .form-label-end {
     text-align: end;
  }

  .form-label-center{
    text-align: center;
  }

  .w-btn {
    width: auto;
    margin: 5px;
  }
}

// start primNg style
.p-form-control {
  width: 100%;
  min-height: 38px;
  background-color: #fff !important;
  border-radius: 0.375rem !important;
  .p-placeholder {
      font-size: 16px;
      color: #222 !important;
  }

  .p-inputtext {
      padding: 7px 14px;
      font-size: 16px;
      width: 100%;
      border-radius: 0.375rem;
      border: 1px solid #b1b7c1;
  }

  .p-dropdown-label {
      padding: 7px 14px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      padding: 0px;
  }

  .p-dropdown {
      background: #f0efee;
      color: #222222;
  }

  ul {
      li {
          .item-box {
              padding: 0.75rem 1.25rem;
          }
      }
  }

  .p-button {
      background: #FFF;
      color: #000;
      border: 1px solid #b1b7c1
  }

  .p-button:enabled:hover {
      background: #FFF;
      color: #000;
      border: 1px solid #b1b7c1
  }

  .p-highlight {
      color: #554242 !important;
      background: #f9b115 !important;
  }

  .p-chips-multiple-container {
     padding: 0rem 0.75rem !important;
  }

  .p-chips-token {
    background: #f1f3f4 !important;
    color: gray !important;
    border: 1px solid !important;
    margin: 5px 0px
  }

  .p-focus{
    border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
    box-shadow: 0 0 0 0.25rem rgb(30 30 30 / 25%) !important;
  }



}

.p-multiselect{
  border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
  height: 38px;
}

.p-multiselect:not(.p-disabled).p-focus{
    border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
    box-shadow: 0 0 0 0.25rem rgb(30 30 30 / 25%) !important;
}

.p-multiselect .p-multiselect-trigger {
  border-left: 1px solid var(--cui-input-border-color, #b1b7c1);
  color: white !important;
  background: #1e1e1e !important;
}

.p-overlay {
  z-index: 2102!important;
}

.ng-trigger-overlayAnimation {
  z-index: 1030 !important;
}

.p-inputtext:enabled:focus{
  border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
  box-shadow: 0 0 0 0.25rem rgb(30 30 30 / 25%) !important;
}

.p-datatable .p-datatable-thead > tr > th {
  font-weight: 800 !important;
  font-size: 13px !important;
  background: #a7a7a721 !important;
}

.p-span{
  font-size: 15px !important;
}

.p-datatable {
  border: 2px solid #dee2e6;
}

.p-datepicker table td {
  padding: 0rem !important;
}
.p-datepicker .p-datepicker-header{
  padding: 0rem !important;

}
.p-datepicker .p-timepicker{
  padding: 0rem !important;

}
.p-datepicker .p-datepicker-buttonbar{
  padding: 0rem !important;

}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  right: 2.75rem !important;
}

// end primNg style

